import { axios } from '@/utils/request'

// 此处封装的方法为返回一个异步请求对象
// 使用该接口时将该对象放入Promiss中进行处理

// 上传图片
export function uploadImage(parameter) {
    return axios({
        url: '/admin/file/uploadImage',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 上传普通图片
export function uploadGeneralImage(parameter) {
    return axios({
        url: '/admin/file/uploadGeneralImage',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除上传的文件
export function deleteGeneralFile(parameter) {
    return axios({
        url: '/admin/file/deleteGeneralFile',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 上传文件
export function uploadFile(parameter) {
    return axios({
        url: '/admin/file/uploadFile',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除上传的文件
export function deleteFile(parameter) {
    return axios({
        url: '/admin/file/deleteFile',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 下载文件
export function downFile(parameter) {
    return axios({
        url: '/admin/file/downFile',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
